import "./Input.css";

const Input = ({ onChangeValue, value, type, validate, onEnterKey, ...props }) => {

    const getClassName = () => {
        if (type === "number") return "input-number"
    }

    const handleOnChange = (value) => {
        return onChangeValue(type === "number" ? parseInt(value) : value);
    }

    const handleKey = (event) => {
        if (event.key === 'Enter') {
            if (onEnterKey) onEnterKey();
        }
    }

    return (
        <input
            className={`input ${getClassName()}`}
            type={type}
            onKeyPress={handleKey}
            onChange={(e) => {
                if (isNaN(e.target.value)) return "";
                if (validate && !validate(e.target.value)) return;
                return handleOnChange(e.target.value);
            }}
            value={value}
            {...props}
        />
    );
}

export default Input;